<script setup lang="ts">
import __MACROS_useVModel from "/vue-macros/define-models/use-vmodel";
defineEmits(["update:show"]);
import { breakpointsTailwind } from '@vueuse/core'
import type { PDFPageProxy } from 'pdfjs-dist'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.min.mjs?url'

const props = defineProps({
  file: null,
  title: null,
  lazy: { type: Boolean },
  noDownload: { type: Boolean },
  show: { type: Boolean }
})
const { show } = __MACROS_useVModel("show")

const shown = ref(false)

watchEffect(() => {
  if (show.value)
    shown.value = true
})

const load = computed(() => props.lazy ? shown.value : true)

const breakpoints = useBreakpoints(breakpointsTailwind)

const greaterThanMd = breakpoints.greaterOrEqual('md')

const pdfjsLib = computedAsync(() => import('pdfjs-dist').then((pdfjs) => {
  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker
  return pdfjs
}))

const pdfDocument = asyncComputed(() => (props.file && load.value) ? pdfjsLib.value?.getDocument(props.file).promise : null)

const metaData = asyncComputed(() => pdfDocument.value?.getMetadata() ?? null)

const pageCount = computed(() => pdfDocument.value?.numPages ?? 0)

const pages = asyncComputed(() => {
  const pagePromises: Promise<PDFPageProxy>[] = []
  if (pdfDocument.value) {
    for (let index = 0; index < pageCount.value; index++) {
      const pagePromise = pdfDocument.value.getPage(index + 1)
      pagePromises.push(pagePromise)
    }
  }
  return Promise.all(pagePromises)
}, null)

const hasDocument = computed(() => !!props.file && !!pdfDocument.value && Array.isArray(pages.value))
const pageContainer = ref<HTMLDivElement>()
</script>

<template>
  <Modal
    v-if="load"
    v-model:show="show" class="min-h-90vh" :content-class="{
      'flex flex-col': !hasDocument,
      'overflow-hidden position-relative': hasDocument,
    }"
  >
    <template #header>
      <div class="bg-primary px-3 py-1 text-white flex justify-between items-center">
        <div class="overflow-hidden text-ellipsis flex-grow-1 pr-2">
          {{ ((props.title ?? metaData?.info as any)?.Title) ?? 'Pdf Dokument' }}
        </div>
        <div class="flex items-center justify-end flex-shrink-0 flex-grow-0">
          <a
            v-if="!props.noDownload"
            title="Download" :href="props.file" :download="props.title ?? 'dokument.pdf'" type="button"
            class="border-none"
          >
            <div class="i-mdi-download" />
          </a>
        </div>
        <div />
      </div>
    </template>
    <div v-if="!hasDocument" id="loader" class="w-full h-full flex items-center justify-center flex-grow-1">
      <img class="h-40" src="/line-md_loading-twotone-loop.svg" alt="">
    </div>

    <div ref="pageContainer" class="h-full overflow-auto absolute top-0 left-0 w-full">
      <template v-if="hasDocument">
        <template v-for="page, index in pages" :key="index">
          <PdfPage v-if="page" :page="page" />
        </template>
      </template>
    </div>
  </Modal>
</template>
