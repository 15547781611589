button:
  about: Über
  back: Zurück
  go: Los
  home: Startseite
  toggle_dark: Dunkelmodus umschalten
  toggle_langs: Sprachen ändern
intro:
  desc: Vite Startvorlage mit Vorlieben
  dynamic-route: Demo einer dynamischen Route
  hi: Hi, {name}!
  aka: Auch bekannt als
  whats-your-name: Wie heißt du?
not-found: Nicht gefunden
product: Produkt
product_search: Produktsuche
object_selection: Objektauswahl
price_query: Bestellen / Bestandsprüfung
inventory_check: Bestandsprüfung
orders: Bestellverlauf
favorites: Favoriten
objects: Objekte
profile: Profil
cart: Warenkorb
Subtotal: Zwischensumme
order_total: Bestellsumme
Shipment: Versand
total_price: Gesamtpreis
total: Gesamt
quantity: Menge
price: EK-Preis
from: Ab
order_search: Bestellungen suchen
quantity_price: Gesamtpreis
productProperty:
  width: Breite
  length: Länge
  height: Höhe
width: Breite (cm)*
height: Höhe (cm)*
reflect: Spiegeln
greyscale: Graustufen
note: Bitte rechnen Sie jeweils 5-10 cm in Breite und Höhe hinzu.
art_num: Artikel-Nr.
address: Anschrift
Contact_Person: Ansprechpartner
tel: Telefon
details: Details
object_details: Objectdetails
edit: Bearbeiten
save: Speichern
object: Objekt
legal: Rechtliches
imprint: Impressum
Conditions: AGB
right_of_withdrawal: Widerrufsrecht
privacy: Datenschutz
Configure: Konfigurieren
back: zurück
choose: Wählen Sie Ihre Produkte aus
price_vat: (*) alle Preise zzgl. MwSt
roll: Rolle
available: Verfügbar
notavailable: bald wieder verfügbar
not_Found: Nicht gefunden
loading: Wird geladen...
search_msg: Wählen Sie Ihre Produkte aus der Suche aus !
order_now: Jetzt bestellen
delete: löschen
billing_address: Rechnungsadresse
billing_address_info: >-
  Geben Sie Ihre Rechnungsinformationen ein oder wählen Sie eine bestehende
  Adresse aus. Mit * markierte Felder sind Pflichtangaben.
company: Firma
staff: Personal
street: Straße / Hausnummer
location: PLZ / Ort
name: Name
name1: Name 1
name2: Name 2
name3: Name 3
shipping_address: Lieferadresse
shipping_address_info: >-
  Geben Sie Ihre Versandinformationen ein oder wählen Sie eine bestehende
  Adresse aus.
remarks: Bemerkungen
continue: Weiter
agreement: Zahlungspflichtig bestellen
person: Zugangsdaten
adress: Adressdaten
customernumber: Kundennummer
password_old: Altes Passwort
password_new: Neues Passwort
selectAnOption: bitte eine Option auswählen
noObjectChosen: kein Objekt ausgewählt
plz: PLZ
city: Stadt
createNewShippingAddress: neue erstellen
noBillingAddressesAvaliable: keine Rechnungadressen verfügbar
start: Start
end: Ende
addition: Zusatz
pleaseWait: Bitte warten...
duration: Laufzeit
head_content: >-
  Große Auswahl Tapeten ✓ kurze Lieferzeiten, Premiumanbieter von
  Wandbekleidung, Stuck und Bodenbeläge
menge: Menge
Preis: Preis
Gesamt: Gesamt
Wiederbestellung: erneut bestellen
Name: Name
Summe: Summe
amount: Anzahl
create_password: Passwort erstellen
new_password: Neues Passwort
create_password_success: Passwort erfolgreich gesetzt
create_password_failed: Passwort Erstellung fehlgeschlagen
logout: Abmelden
change_password_success: Passwort erfolgreich geändert
change_password_fail: Passwort änderung fehlgeschlagen
checkout:
  address: Adresse
  shipping: Versand
  overview: Zusammenfassung
  new_address: Neue Adresse anlegen
country:
  de: Deutschland
  deutschland: Deutschland
  d: Deutschland
  D: Deutschland
  at: Österreich
cancel: Abbrechen
shipping:
  default: Versand frei Haus
  express: Expressversand
  next_day: Next Day 10:00 Uhr
  24h: 24h Lieferung
  48h: 48h Lieferung
  custom: Wunschliefertermin
  guetersloh: Abholung Lager Gütersloh
  express_info: Die Kosten ermittelt unser Team gerne für Sie
payment:
  bill: Zahlung auf Rechnung
payment_methode: Bezahlmethode
shipping_methode: Versandart
shipping_methode_info: Wählen Sie eine Versandart aus.
order: Kostenpflichtig bestellen
order_see_details: Details sehen
streetAndNumber: Straße und Hausnummer
postal: PLZ
wishlists: Online Studio - Merklisten
commission: Kommission/Bemerkung
password_repeat: Passwort wiederholen
cookie: Cookie Einstellungen
