<script setup lang="ts">
import { IonMenu } from '@ionic/vue'

const props = defineProps({
  hideNavigation: { type: Boolean },
  hideUser: { type: Boolean }
})

const userStore = useUser()

const { t } = useI18n()

const router = useRouter()

const menu = ref<typeof IonMenu | null>(null)
</script>

<template>
  <IonPage id="main-content">
    <IonMenu v-if="(userStore.hasUser && !props.hideNavigation)" ref="menu" content-id="main-content" menu-id="app-menu">
      <IonHeader class="mobile">
        <IonToolbar />
        <div id="logo-box">
          <RouterLink to="/">
            <Logo class="h-11" />
          </RouterLink>
        </div>
      </IonHeader>
      <IonContent class="menu">
        <Menu @click="menu?.close()" />
      </IonContent>
    </IonMenu>
  </IonPage>

  <IonHeader class="ion-no-border" lines="none">
    <IonToolbar>
      <div class="flex justify-end items-center pt-3 2sm-pt-0">
        <IonButtons slot="end">
          <IonButton>
            <IonMenuButton v-if="userStore.hasUser && !props.hideNavigation" />
          </IonButton>
          <MkButton
            v-if="userStore.hasUser && !props.hideNavigation"
            @click="router.back()"
          >
            {{ t('button.back') }}
          </MkButton>
        </IonButtons>
        <RouterLink v-if="userStore.hasUser && !props.hideUser" slot="end" to="/account" class="i-ion-person-circle-outline text-3xl text-primary hover:text-red-900" />
      </div>
    </IonToolbar>

    <div id="logo-box">
      <RouterLink to="/">
        <Logo class="h-7 2sm:h-10 md:h-12" />
      </RouterLink>
    </div>
  </IonHeader>
  <Nav v-if="userStore.hasUser && !props.hideNavigation" />
</template>

<style lang="scss" scoped>
ion-menu {
  position: fixed;
}

ion-header {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0;
  ion-toolbar {
    margin-bottom: -2px;
    border-top: 15px solid var(--primary);
  }
  .bg {
    background-color: var(--primary);
  }
  ion-menu-button {
    @media screen and (min-width: 767px) {
      display: none;
    }
  }
  #logo-box {
    position: absolute;
    top: -10px;
    left: 10px;
    padding: 10px;
    z-index: inherit;
    background-color: white;
    img {
      display: block;
      height: 50px;
    }
  }
}
ion-icon {
  color: var(--primary);
  cursor: pointer;
}
.mobile {
  padding-top: 12px;
  #logo-box {
    top: 0;
  }
}
</style>
